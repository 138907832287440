
.zoomer {
    position: fixed;
    right: 20px;
    bottom: 50px;
}

.zoom-button {
    width: 30px;
    height: 30px;
    border: solid 2px #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    background-color: white;
}

.zoom-button>img {
    width: 20px;
    height: 20px;
}

.zoom-button:active {
    background-color: #d9d9d9;
}