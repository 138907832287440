
.question-option {
    margin: 10px auto;
    max-width: 300px;
    display: flex;
    align-items: center;
}

.question-option button {
    height: fit-content;
    display: block;
    border: none;
    width: 100%;
    padding: 16px;
    background: transparent;
}

.question-option span {
    white-space: pre-wrap;
}

.box {
    flex: 1;
    /*clip-path: polygon(*/
    /*        6px 0, calc(100% - 6px) 0,*/
    /*        100% 6px, 100% calc(100% - 6px),*/
    /*        calc(100% - 6px) 100%, 6px 100%,*/
    /*        0 calc(100% - 6px), 0 6px);*/

    background: #FDFBF7;
    /*background: linear-gradient(-45deg, #EFE8DC 4px, #FDFBF7 0) bottom right,*/
    /*linear-gradient(45deg, #EFE8DC 4px, #FDFBF7 0) bottom left,*/
    /*linear-gradient(135deg, #EFE8DC 4px, #FDFBF7 0) top left,*/
    /*linear-gradient(-135deg, #EFE8DC 4px, #FDFBF7 0) top right;*/
    /*background-size: 50% 50%;*/
    /*background-repeat: no-repeat;*/
    border: solid 2px #EFE8DC;
    border-radius: 4px;
}

.box button {
    outline: none;
}

.correct .box {
    border-color: #C1A16B;
    background: #C1A16B;
}

.correct button {
    color: white;
}

.wrong .box {
    /* 不知道为啥全部复制过来才能生效。如果只覆盖 liner-gradient属性 就只有右下角生效。***！ */
    background: #FDFBF7;
    /*background: linear-gradient(-45deg, #DB614F 4px, #FDFBF7 0) bottom right,*/
    /*linear-gradient(45deg, #DB614F 4px, #FDFBF7 0) bottom left,*/
    /*linear-gradient(135deg, #DB614F 4px, #FDFBF7 0) top left,*/
    /*linear-gradient(-135deg, #DB614F 4px, #FDFBF7 0) top right;*/
    /*background-size: 50% 50%;*/
    /*background-repeat: no-repeat;*/
    border-color: #DB614F;
}

.sign {
    width: 24px;
    height: 24px;
    margin: 0 10px;
}

.sign img {
    width: 100%;
    height: 100%;
}