
.full-box, html, body, #root, .App, .App > div {
    width: 100%;
    height: 100%;
}

.full-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loading-box {
    width: 150px;
    min-height: 180px;
    background-color: #0000009e;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loading-box > span {
    color: white;
    display: block;
    margin-top: 30px;
}

.loading-box > img {
    animation: rotation 2s infinite;
    width: 60px;
    height: 60px;
}

@keyframes rotation {
    100% {
        transform: rotate(-360deg);
    }

}
