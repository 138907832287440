
.question-body {
    margin: auto;
    max-width: 800px;
    height: 100%;
    padding: 0 20px;
}

.question-title {
    margin-bottom: 40px;
    display: inline-block;
}